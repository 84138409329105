import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/packages/website/src/components/DocsLayout.tsx";
import QuickStart from "../../content/examples/quick-start.mdx";
import EaseCompare from "../../content/examples/ease-compare.mdx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`It's super easy to start animating with React Ensemble. This guide will explain step-by-step how to create this animation:`}</p>
    <QuickStart mdxType="QuickStart" />
    <p><strong parentName="p">{`Note:`}</strong>{` Most of the examples in these docs are `}<strong parentName="p">{`interactive!`}</strong>{` Click the button above to expand this example's code playground. You can edit it to see how the animation changes right on the page!`}</p>
    <h2 {...{
      "id": "part-0-prerequisites"
    }}>{`Part 0: Prerequisites`}</h2>
    <p>{`This tutorial assumes you have enough React experience to be comfortable creating a React application, writing function components, and using a few common React Hooks (specifically, `}<inlineCode parentName="p">{`useState`}</inlineCode>{`).`}</p>
    <p>{`If that sounds like you, feel free to `}<a parentName="p" {...{
        "href": "#part-1-setting-the-stage"
      }}>{`skip to the next section`}</a>{`. Otherwise, read on!`}</p>
    <h3 {...{
      "id": "using-react"
    }}>{`Using React`}</h3>
    <p>{`React Ensemble, as the name suggests, is a component library for React. If you're unfamiliar with React (or just need to brush up on the basics!), a good starting point is the `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/hello-world.html"
      }}>{`official React guide`}</a>{`.`}</p>
    <p>{`You don't need to be a React expert by any means to read this guide, but it may be helpful to have experience with two key React features: `}<strong parentName="p">{`function components`}</strong>{` and `}<strong parentName="p">{`the `}<inlineCode parentName="strong">{`useState`}</inlineCode>{` hook`}</strong>{`.`}</p>
    <p>{`All the examples in this guide use React function components, rather than class components. Instead of writing our React components like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`class MyComponent extends React.Component {
  render() {
    const myState = this.state;
    // ...
  }
}
`}</code></pre>
    <p>{`We'll be writing them as pure functions:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const MyComponent = props => {
  const [myState, setMyState] = React.useState(defaultValue);
  // ...
};
`}</code></pre>
    <p>{`If you're confused by that whole `}<inlineCode parentName="p">{`React.useState`}</inlineCode>{` business, don't worry!
The `}<inlineCode parentName="p">{`useState`}</inlineCode>{` function is part of the new Hooks feature in React, which lets us have state in functional components.`}</p>
    <p>{`Here's a quick breakdown of our example:`}</p>
    <ul>
      <li parentName="ul">{`Calling `}<inlineCode parentName="li">{`React.useState`}</inlineCode>{` returns a `}<em parentName="li">{`value`}</em>{` and a `}<em parentName="li">{`setter`}</em>{` for that specific piece of state.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`myState`}</inlineCode>{` is the state's current value. By default, this will equal the `}<inlineCode parentName="li">{`defaultValue`}</inlineCode>{` we passed in.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`setMyState`}</inlineCode>{` is a callback function we can use when we want to update the state to a new value.`}</li>
    </ul>
    <p>{`For more info on the `}<inlineCode parentName="p">{`useState`}</inlineCode>{` hook, check out the `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/hooks-state.html"
      }}>{`React docs`}</a>{`.`}</p>
    <h3 {...{
      "id": "creating-a-new-project"
    }}>{`Creating a New Project`}</h3>
    <p>{`You'll need a working React application to build the animation in this tutorial.
Because all the code will be in a single file, it doesn't really matter which environment you use.
You can try Create React App, an online playground, or any functioning React application.`}</p>
    <p><a parentName="p" {...{
        "href": "https://create-react-app.dev/docs/getting-started/"
      }}>{`Create React App`}</a>{` (CRA) is a super quick way to start a fully functional React app from the command line. To get started, run the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`yarn create react-app my-animation
cd my-animation
yarn start
`}</code></pre>
    <p>{`After a few minutes, you should see the CRA welcome page in your browser. Go ahead and delete the contents of `}<inlineCode parentName="p">{`src/App.js`}</inlineCode>{`, and you're ready to go!`}</p>
    <h2 {...{
      "id": "part-1-setting-the-stage"
    }}>{`Part 1: Setting the Stage`}</h2>
    <p>{`First, let's `}<a parentName="p" {...{
        "href": "/docs/installation"
      }}>{`install`}</a>{` React Ensemble.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`yarn add react-ensemble
`}</code></pre>
    <p><strong parentName="p">{`Note:`}</strong>{` This guide uses JavaScript for clarity, but you can also follow along in `}<a parentName="p" {...{
        "href": "https://www.typescriptlang.org/"
      }}>{`TypeScript`}</a>{`. For more information, see the guide on `}<a parentName="p" {...{
        "href": "/docs/guides/using-typescript"
      }}>{`using Typescript with React Ensemble`}</a>{`.`}</p>
    <h3 {...{
      "id": "the-container"
    }}>{`The Container`}</h3>
    <p>{`Create a new file for our animation and add the following code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React from "react";
import { Timeline, Controller, Lib, TrackUtils } from "react-ensemble";

const MyAnimation = () => {
  return (
    <div
      style={{
        height: 150,
        width: "100%",
        position: "relative",
        display: "flex",
        justifyContent: "center"
      }}
    >
      Hi!
    </div>
  );
};

export default MyAnimation;
`}</code></pre>
    <p>{`Here's what we did:`}</p>
    <ol>
      <li parentName="ol">{`Imported `}<inlineCode parentName="li">{`Timeline`}</inlineCode>{`, `}<inlineCode parentName="li">{`Controller`}</inlineCode>{`, `}<inlineCode parentName="li">{`Lib`}</inlineCode>{`, and `}<inlineCode parentName="li">{`TrackUtils`}</inlineCode>{` from React Ensemble. Don't worry, we'll explain each of these as needed.`}</li>
      <li parentName="ol">{`Created a component that returns a `}<a parentName="li" {...{
          "href": "https://css-tricks.com/snippets/css/a-guide-to-flexbox/"
        }}>{`flexbox`}</a>{` div. This div will contain our animation.`}</li>
    </ol>
    <p>{`Running the code should render this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live startHidden",
        "live": true,
        "startHidden": true
      }}>{`() => {
  return (
    <div
      style={{
        height: 250,
        width: "100%",
        position: "relative",
        display: "flex",
        justifyContent: "center"
      }}
    >
      <div>Hi!</div>
    </div>
  );
};
`}</code></pre>
    <h3 {...{
      "id": "the-circle"
    }}>{`The Circle`}</h3>
    <p>{`Next, we'll add the star of our show: a circle.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live renderOnly",
        "live": true,
        "renderOnly": true
      }}>{`() => {
  return (
    <div
      style={{
        height: 150,
        width: "100%",
        position: "relative",
        display: "flex",
        justifyContent: "center"
      }}
    >
      <div
        style={{
          position: "relative",
          borderRadius: 15,
          width: 30,
          height: 30,
          backgroundColor: "__primary",
          left: 0,
          top: 35,
          opacity: 1
        }}
      />
    </div>
  );
};
`}</code></pre>
    <p>{`First, replace the `}<inlineCode parentName="p">{`<div>Hi</div>`}</inlineCode>{` in our component with the following div:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<div
  style={{
    position: "relative",
    borderRadius: 15,
    width: 30,
    height: 30,
    backgroundColor: "__primary",
    left: 0,
    top: 35,
    opacity: 1
  }}
/>
`}</code></pre>
    <p>{`Giving us our perfect circle:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live startHidden",
        "live": true,
        "startHidden": true
      }}>{`() => {
  return (
    <div
      style={{
        height: 250,
        width: "100%",
        position: "relative",
        display: "flex",
        justifyContent: "center"
      }}
    >
      <div
        style={{
          position: "relative",
          borderRadius: 15,
          width: 30,
          height: 30,
          backgroundColor: "__primary",
          left: 0,
          top: 35,
          opacity: 1
        }}
      />
    </div>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "part-2-making-things-move"
    }}>{`Part 2: Making Things Move`}</h2>
    <p>{`In this part, we'll breathe some life into our animation.`}</p>
    <p>{`Start by adding the `}<inlineCode parentName="p">{`Controller`}</inlineCode>{` and `}<inlineCode parentName="p">{`Timeline`}</inlineCode>{` components we imported earlier into your JSX, right above the container from the last part.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const MyAnimation = () => {
  return (
    <>
      <Controller>
        {props => <Timeline {...props} defaultState={{}} track={[]} />}
      </Controller>
      <div>{/* Animation Container */}</div>
    </>
  );
};
`}</code></pre>
    <p>{`What do these components do? Here's a quick summary:`}</p>
    <h3 {...{
      "id": "timeline"
    }}>{`Timeline`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`Timeline`}</inlineCode>{` component is the engine behind our animation.
It takes our animation data as props (at a minimum, the animation's `}<inlineCode parentName="p">{`track`}</inlineCode>{` and `}<inlineCode parentName="p">{`defaultState`}</inlineCode>{`) and computes each frame in real time.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` `}<inlineCode parentName="p">{`Timeline`}</inlineCode>{` doesn't render anything by itself. It simply returns data that we use to draw our animation.`}</p>
    <h3 {...{
      "id": "controller"
    }}>{`Controller`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`Controller`}</inlineCode>{` is an `}<em parentName="p">{`optional`}</em>{` component that automatically configures a `}<inlineCode parentName="p">{`Timeline`}</inlineCode>{` and provides some basic UI controls for your animation.`}</p>
    <p>{`You can animate using only the `}<inlineCode parentName="p">{`Timeline`}</inlineCode>{` component if you want more control, but using a `}<inlineCode parentName="p">{`Controller`}</inlineCode>{` is an easy way to get up and running right away.`}</p>
    <h3 {...{
      "id": "default-state"
    }}>{`Default State`}</h3>
    <p>{`The prop `}<inlineCode parentName="p">{`defaultState`}</inlineCode>{` is required by `}<inlineCode parentName="p">{`Timeline`}</inlineCode>{`. It contains the starting state of your animation.`}</p>
    <p>{`Outside your component, define your animation's default state:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const defaultAnimState = {
  x: -250,
  opacity: 0
};
`}</code></pre>
    <p>{`Then, pass your default state as a prop to your timeline:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Timeline {...props} defaultState={defaultAnimState} track={[]} />
`}</code></pre>
    <h3 {...{
      "id": "drawing-from-data"
    }}>{`Drawing from Data`}</h3>
    <p>{`Next, we need to rewrite our circle from earlier to rely on our animation state. Create a `}<inlineCode parentName="p">{`useState`}</inlineCode>{` hook in the component to track your animation state.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const MyAnimation = () => {
  const [animState, setAnimState] = React.useState(defaultAnimState);
  // return ...
};
`}</code></pre>
    <p>{`Remember, the `}<inlineCode parentName="p">{`useState`}</inlineCode>{` syntax means that `}<inlineCode parentName="p">{`animState`}</inlineCode>{` will always refer to the current state of our animation, starting with `}<inlineCode parentName="p">{`defaultAnimState`}</inlineCode>{`.`}</p>
    <p>{`Now, replace the `}<inlineCode parentName="p">{`left`}</inlineCode>{` and `}<inlineCode parentName="p">{`opacity`}</inlineCode>{` properties in the circle div to rely on the `}<inlineCode parentName="p">{`x`}</inlineCode>{` and `}<inlineCode parentName="p">{`opacity`}</inlineCode>{` properties of the animation state.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<div
  style={{
    position: "relative",
    borderRadius: 15,
    width: 30,
    height: 30,
    backgroundColor: "__primary",
    left: animState.x, // Changed
    top: 35,
    opacity: animState.opacity // Changed
  }}
/>
`}</code></pre>
    <p>{`If you look at the output of your app, you should see that the circle has disappeared. This means your animation is working! After all, you changed its opacity to zero and moved it 250px to the left.`}</p>
    <h3 {...{
      "id": "the-track"
    }}>{`The Track`}</h3>
    <p>{`The final piece is the `}<strong parentName="p">{`track`}</strong>{`, the `}<inlineCode parentName="p">{`Timeline`}</inlineCode>{` prop that determines when and how our animation state changes.`}</p>
    <p>{`A track is an array of objects called `}<strong parentName="p">{`regions`}</strong>{`.
Each region contains a description of how certain state properties should change over a given time frame.`}</p>
    <p>{`You can describe regions using these fields:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`start`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`stop`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`duration`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`state`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`loop`}</inlineCode></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/api/types#trackregion"
        }}>{`and more!`}</a></li>
    </ul>
    <p>{`Lets animate our circle coming in and out of frame. Add the following code `}<em parentName="p">{`outside`}</em>{` the component:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const track = [
  {
    duration: 1500,
    state: {
      opacity: { to: 1 },
      x: { to: 0 }
    }
  },
  {
    duration: 1500,
    state: {
      opacity: { to: 0 },
      x: { to: 250 }
    }
  }
];
`}</code></pre>
    <p>{`Reading our track top-to-bottom, we can see that for the first 1.5 seconds (1500 ms), the circle's opacity will change to 1 and its x-coordinate will change to 0.
Over the next 1.5 seconds, the circle will fade back out and travel off the right side of the screen.`}</p>
    <p>{`Finally, we'll make two additions to the timeline:`}</p>
    <ul>
      <li parentName="ul">{`Pass in the `}<inlineCode parentName="li">{`track`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Hook up the Timeline's `}<inlineCode parentName="li">{`onUpdate`}</inlineCode>{` callback to set our animation state on every frame.`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Timeline
  {...props}
  defaultState={defaultAnimState}
  track={track}
  onUpdate={({ state }) => setAnimState(state)}
/>
`}</code></pre>
    <p>{`On your live app, press play. `}<em parentName="p"><strong parentName="em">{`It's alive!!`}</strong></em></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live startHidden",
        "live": true,
        "startHidden": true
      }}>{`() => {
  ////////
  // Note: this block should be outside the component in your app
  const defaultAnimState = {
    x: -250,
    opacity: 0
  };

  const track = [
    {
      duration: 1500,
      state: {
        opacity: { to: 1 },
        x: { to: 0 }
      }
    },
    {
      duration: 1500,
      state: {
        opacity: { to: 0 },
        x: { to: 250 }
      }
    }
  ];
  // End block (the rest should be inside your component)
  ////////

  const [animState, setAnimState] = React.useState(defaultAnimState);

  return (
    <>
      <Controller>
        {props => (
          <Timeline
            {...props}
            defaultState={defaultAnimState}
            track={track}
            onUpdate={({ state }) => setAnimState(state)}
          />
        )}
      </Controller>
      <div
        style={{
          height: 250,
          width: "100%",
          position: "relative",
          display: "flex",
          justifyContent: "center"
        }}
      >
        <div
          style={{
            position: "relative",
            borderRadius: 15,
            width: 30,
            height: 30,
            backgroundColor: "__primary",
            left: animState.x,
            top: 35,
            opacity: animState.opacity
          }}
        />
      </div>
    </>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "part-3-shape-shifting"
    }}>{`Part 3: Shape Shifting`}</h2>
    <p>{`In this part, we'll create the next section of our animation by using two utility modules exported by React Ensemble: `}<inlineCode parentName="p">{`TrackUtils`}</inlineCode>{` and `}<inlineCode parentName="p">{`Lib`}</inlineCode>{`.`}</p>
    <p>{`First, add the following code `}<em parentName="p">{`outside`}</em>{` your component, right after the imports:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const { multi } = TrackUtils;
const { d3Ease } = Lib;
`}</code></pre>
    <h3 {...{
      "id": "trackutilsmulti"
    }}>{`TrackUtils.multi`}</h3>
    <p><inlineCode parentName="p">{`TrackUtils`}</inlineCode>{` contains utility functions related to manipulating and parsing animation tracks.`}</p>
    <p>{`The largest of these functions is `}<inlineCode parentName="p">{`TrackUtils.gen`}</inlineCode>{`, which parses a track into a queryable animation object.
`}<inlineCode parentName="p">{`Timeline`}</inlineCode>{` uses this function under the hood, but you can also import it yourself. For more information, see the `}<a parentName="p" {...{
        "href": "/docs/api/trackutils"
      }}>{`TrackUtils.gen docs`}</a>{`.`}</p>
    <p>{`For this example, we'll be using `}<inlineCode parentName="p">{`TrackUtils.multi`}</inlineCode>{`. Add the following code to your track, right after the first region:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// First region goes here (circle in)
multi([
  {
    duration: 100,
    state: {
      circleMorph: { to: 0 } // Will morph the circle into a square
    }
  },
  {
    duration: 1000,
    state: {
      width: { to: 50 }
    }
  }
]),
// Last region goes here (circle out)
`}</code></pre>
    <p>{`Also, you'll need to update the default state and circle div to use these new fields:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// Outside your component:
const defaultAnimState = {
  circleMorph: 1, // New
  width: 30, // New
  x: -250,
  opacity: 0
};

// In the circle div:
<div
  style={{
    position: "relative",
    borderRadius: animState.width * animState.circleMorph * 0.5, // Changed
    width: animState.width, // Changed
    height: animState.width, // Changed
    backgroundColor: "__primary",
    left: animState.x,
    top: 35,
    opacity: animState.opacity
  }}
/>;
`}</code></pre>
    <p>{`React Ensemble executes regions `}<em parentName="p">{`sequentially`}</em>{`, meaning one region won't start until the one before it has finished.
If we were to configure a region to start when another region was still running, `}<inlineCode parentName="p">{`TrackUtils.gen`}</inlineCode>{` would throw an error.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "510px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c61ee4d9dc94ed064a7a65a885231a2d/0abdd/track-diagram.drawio.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "34%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsSAAALEgHS3X78AAABaUlEQVQoz62PP0/CUBTFXxz9AiauJn4bB2yhLaWFNoVEa+IiCfoJnFxdjEKEQTeNAUyUhUQHUyUKFBT/QFQESV+7EZ70+Voqyu5LfjnnnnNzkwdgVbWMimoQICSYjlZV6MymrsLHixgsFxT4ebs8zuEkBsl7sKz2e9fLC6C+J+F6ipCUsE70LkOUZDqZa2TWtkVc3OKxvhtx92rJUfeXyk4YN49i+ENbosALw+CGwAybIms3Qoxd4vz2e4SzX8Mj2hJnd+Sg2z0JjP0ssG7+5kH8sB3ivhorPC7l5EXwwASGRT810PkAaoVZdM8zyIzyyFB4BJVfvWH96DJAoSrZ68pBZHodYWDJfL9JDl4dSz5gxSUMNyRsrUu4kyDfigvYJB4mJnF6SDA9/ckNx69FcHdTwXohyoKzQ9GXTwt0dj9Enx+ItHYquz6fniTrac5j3GUE+iTFU1peZnBrdRYAMDcP/udNATAz/Q2qtz10t1u3DwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Track Diagram",
            "title": "Track Diagram",
            "src": "/static/c61ee4d9dc94ed064a7a65a885231a2d/0abdd/track-diagram.drawio.png",
            "srcSet": ["/static/c61ee4d9dc94ed064a7a65a885231a2d/5a46d/track-diagram.drawio.png 300w", "/static/c61ee4d9dc94ed064a7a65a885231a2d/0abdd/track-diagram.drawio.png 510w"],
            "sizes": "(max-width: 510px) 100vw, 510px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`So what about if we want to have two regions overlap?
For instance, here the circle should morph into a square over 0.1 seconds and increase its width to 50 over 1 second.
We want both of these regions to start at the same time.`}</p>
    <p>{`For executing regions `}<em parentName="p">{`concurrently`}</em>{`, React Ensemble offers the concept of `}<strong parentName="p">{`track layers`}</strong>{`.
A layer is essentially a sub-track for regions to be placed in so they can run at the same time as other sub-tracks (layers).`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "390px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d7f21947621a4472c20b658a4c8dbe62/727ba/layer-multi-diagram.drawio.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "77.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsSAAALEgHS3X78AAACrklEQVQ4y6XS/08SYRwH8Gfjh7bW6t/o9/6Rlk6eu3sODsLgjmwG2qYuqbVcmxmKf0Hzh5q1NSc/VGtLGIp8xyS0bBNE5I4DIsPaOLlPd1gLzTZan+2153Ofe+/zy/OgSlLIVpNCpRh2SDshe5sc5/dqKeF7Oc4PI63kBG9A3ZacEiq7cR7CCxbwPyYQesHBh2A/fNsahFpauKtnqmmh+4XpOU5KDWDYcNOtrJtWs0O0mhmglMw9E2y8sXn0TPIJ1/3C2EMilRgKShZKLXHayVFq3cIoBScLkafm9sLgFN39wuQ0K26zGF4brxz6ey63VpneVt5CNfODLCSeceN6ZsVHDHJCQN1AUS/Zr5oJNBwm+HyNhX07Cw0bC+JNDuLPufv6wvV5yxkx6jCIMd6gX5B2kZpf53Eou3A1GLyNs8vj1Loms+yhMqExvBb2knxuxW5F/1q1d8JENeucrWSdMxrfTzO1985ZOco/KEf58VpC8Mgx3iOtOjxShPeUNdKf7pRj/CjSnkZDLbiguT0ISu43/Rv2XFDfuAHJV/3wMcSDmL4OUHRp3B2nZlfrxSE42BwAtPXSVkr4WEj7WCXlYw876bPYI6IEJihlZZJRYl6iZ07TTHhJKzNvqaPIFCN96umDAsFqnsZwDGWEIoOhbKJAZCkoEgw7JzOaHIPVkhHDqgs3UGKaSKKVhpqTqBWegU5VTYT0QoDqgTDTC2lzH4h2uj3vzMkCo361E0iNUA0UnWRKRQpD3cY0q1Za6VTTFExYyeA+ZYsxKnkWK7LlaN6Zq1jp5r6ZUeNu/AWtzXEH28Mm2B0xQ+EUxVEziGMc7I1y7f60zI6eu2WGpJcFtBnoZwN+jgv6OUZDTtL+kbeLHFlaPOqDf7Hk59hczEHpT/EsQhcvIXT+Avr/OvcDK2B4ZLdTCm0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Layer Diagram",
            "title": "Layer Diagram",
            "src": "/static/d7f21947621a4472c20b658a4c8dbe62/727ba/layer-multi-diagram.drawio.png",
            "srcSet": ["/static/d7f21947621a4472c20b658a4c8dbe62/5a46d/layer-multi-diagram.drawio.png 300w", "/static/d7f21947621a4472c20b658a4c8dbe62/727ba/layer-multi-diagram.drawio.png 390w"],
            "sizes": "(max-width: 390px) 100vw, 390px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Layers are executed independently of one another. Therefore, if two regions are put on separate layers, they will run at the same time.`}</p>
    <p>{`Our call to `}<inlineCode parentName="p">{`TrackUtils.multi`}</inlineCode>{` does just that: it puts the first region into one layer and the second region into another.
When we run the code, both regions execute at the same time!`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live startHidden",
        "live": true,
        "startHidden": true
      }}>{`() => {
  ////////
  // Note: this block should be outside the component in your app
  const { multi } = TrackUtils;
  const { d3Ease } = Lib;

  const defaultAnimState = {
    circleMorph: 1,
    width: 30,
    x: -250,
    opacity: 0
  };

  const track = [
    {
      duration: 1500,
      state: {
        opacity: { to: 1 },
        x: { to: 0 }
      }
    },
    multi([
      {
        duration: 100,
        state: {
          circleMorph: { to: 0 }
        }
      },
      {
        duration: 1000,
        state: {
          width: { to: 50 }
        }
      }
    ]),
    {
      duration: 1500,
      state: {
        opacity: { to: 0 },
        x: { to: 250 }
      }
    }
  ];
  // End block (the rest should be inside your component)
  ////////

  const [animState, setAnimState] = React.useState(defaultAnimState);

  return (
    <>
      <Controller>
        {props => (
          <Timeline
            {...props}
            defaultState={defaultAnimState}
            track={track}
            onUpdate={({ state }) => setAnimState(state)}
          />
        )}
      </Controller>
      <div
        style={{
          height: 250,
          width: "100%",
          position: "relative",
          display: "flex",
          justifyContent: "center"
        }}
      >
        <div
          style={{
            position: "relative",
            borderRadius: animState.width * animState.circleMorph * 0.5, // Changed
            width: animState.width,
            height: animState.width,
            backgroundColor: "__primary",
            left: animState.x,
            top: 35,
            opacity: animState.opacity
          }}
        />
      </div>
    </>
  );
};
`}</code></pre>
    <p><strong parentName="p">{`Note:`}</strong>{` `}<inlineCode parentName="p">{`TrackUtils.multi`}</inlineCode>{` is just nice syntax.
You can use all the features of layering by configuring the right properties on your track regions.
All `}<inlineCode parentName="p">{`multi`}</inlineCode>{` does is add them for you.`}</p>
    <p>{`For example, our `}<inlineCode parentName="p">{`multi`}</inlineCode>{` region is equivalent to this region, which is called a `}<strong parentName="p">{`group`}</strong>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// This is just an example!
// You don't need to paste it into your animation.
{
  regions: [
    {
      layer: "0",
      duration: 100,
      state: {
        circleMorph: { to: 0 }
      }
    },
    {
      layer: "1",
      duration: 1000,
      state: {
        width: { to: 50 }
      }
    }
  ];
}
`}</code></pre>
    <p>{`For more information on layers and groups, see the guides on `}<a parentName="p" {...{
        "href": "/docs/guides/layers"
      }}>{`Layers`}</a>{` and `}<a parentName="p" {...{
        "href": "/docs/guides/groups"
      }}>{`Groups`}</a>{`.`}</p>
    <h3 {...{
      "id": "easing-with-libd3ease"
    }}>{`Easing with Lib.d3Ease`}</h3>
    <p>{`You may have noticed that our shape-shift animation doesn't quite match up with the example at the start of this guide.`}</p>

    <EaseCompare mdxType="EaseCompare" />
    <p>{`Our square doesn't "spring" outward like the goal animation does. This is because our region's `}<strong parentName="p">{`easing function`}</strong>{` is different.`}</p>
    <p>{`Usually, we do not want time to be exactly linear in our animations.
An element that slides in at a constant speed is much less exciting than one that bounces, springs, or boomerangs in.
We can control this effect with an `}<em parentName="p">{`easing function`}</em>{`.`}</p>
    <p>{`Add the following line to the multi-region:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`multi([
  {
    duration: 100,
    state: {
      circleMorph: { to: 0 }
    }
  },
  {
    duration: 1000,
    state: {
      width: { to: 50 }
    },
    easing: d3Ease.easeElastic // New line!
  }
]),
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`easing`}</inlineCode>{` property on a track region sets its time curve.
Here, we are using the `}<inlineCode parentName="p">{`easeElastic`}</inlineCode>{` function provided by the package `}<a parentName="p" {...{
        "href": "https://github.com/d3/d3-ease"
      }}><strong parentName="a">{`d3-ease`}</strong></a>{`.
This easing function will give our shape shift an elastic feel.`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/d3/d3-ease"
      }}><strong parentName="a">{`d3-ease`}</strong></a>{` includes a number of interesting easing functions.
For convenience, React Ensemble exports this package at `}<inlineCode parentName="p">{`Lib.d3Ease`}</inlineCode>{`.
Check out the `}<a parentName="p" {...{
        "href": "/docs/guides/easing-and-interpolating"
      }}>{`Easing and Interpolating guide`}</a>{` for more information.`}</p>
    <h3 {...{
      "id": "shifting-back"
    }}>{`Shifting Back`}</h3>
    <p>{`To make the square shift back into a circle, add another group just after the first one:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`multi([
  {
    duration: 100,
    state: {
      circleMorph: { to: 1 }
    }
  },
  {
    duration: 1000,
    state: {
      width: { to: 30 }
    },
    easing: d3Ease.easeElastic
  }
]),
`}</code></pre>
    <p>{`At this point, the animation should look like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live startHidden",
        "live": true,
        "startHidden": true
      }}>{`() => {
  ////////
  // Note: this block should be outside the component in your app
  const { multi } = TrackUtils;
  const { d3Ease } = Lib;

  const defaultAnimState = {
    circleMorph: 1,
    width: 30,
    x: -250,
    opacity: 0
  };

  const track = [
    {
      duration: 1500,
      state: {
        opacity: { to: 1 },
        x: { to: 0 }
      }
    },
    multi([
      {
        duration: 100,
        state: {
          circleMorph: { to: 0 }
        }
      },
      {
        duration: 1000,
        state: {
          width: { to: 50 }
        },
        easing: d3Ease.easeElastic
      }
    ]),
    multi([
      {
        duration: 100,
        state: {
          circleMorph: { to: 1 }
        }
      },
      {
        duration: 1000,
        state: {
          width: { to: 30 }
        },
        easing: d3Ease.easeElastic
      }
    ]),
    {
      duration: 1500,
      state: {
        opacity: { to: 0 },
        x: { to: 250 }
      }
    }
  ];
  // End block (the rest should be inside your component)
  ////////

  const [animState, setAnimState] = React.useState(defaultAnimState);

  return (
    <>
      <Controller>
        {props => (
          <Timeline
            {...props}
            defaultState={defaultAnimState}
            track={track}
            onUpdate={({ state }) => setAnimState(state)}
          />
        )}
      </Controller>
      <div
        style={{
          height: 250,
          width: "100%",
          position: "relative",
          display: "flex",
          justifyContent: "center"
        }}
      >
        <div
          style={{
            position: "relative",
            borderRadius: animState.width * animState.circleMorph * 0.5,
            width: animState.width,
            height: animState.width,
            backgroundColor: "__primary",
            left: animState.x,
            top: 35,
            opacity: animState.opacity
          }}
        />
      </div>
    </>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "part-4-final-touches"
    }}>{`Part 4: Final Touches`}</h2>
    <p>{`To wrap things up, we'll add one last group to the track. First, add a few more properties to the default state and the circle div:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// Outside the component
const defaultAnimState = {
  circleMorph: 1,
  width: 30,
  color: "__primary", // New line
  x: -250,
  y: 50, // New line
  opacity: 0,
  angle: 0 // New line
};

// The circle div
<div
  style={{
    position: "relative",
    borderRadius: animState.width * animState.circleMorph * 0.5,
    width: animState.width,
    height: animState.width,
    backgroundColor: animState.color, // Changed line
    left: animState.x,
    top: animState.y - animState.width / 2, // Changed line
    opacity: animState.opacity,
    transform: \`rotate(\${animState.angle}deg)\` // Changed line
  }}
/>;
`}</code></pre>
    <p>{`Then, add this group between the two existing groups in the track.
This time, we're passing an array of three arrays into `}<inlineCode parentName="p">{`multi`}</inlineCode>{`, which will put each of these sub-arrays into its own layer.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`multi([
  [
    {
      start: 2500,
      duration: 750,
      state: {
        y: { to: 200 }
      },
      easing: d3Ease.easeExpIn,
      loop: {
        count: 1,
        boomerang: true
      }
    },
    {
      duration: 0,
      state: {
        y: { set: 50 }
      }
    }
  ],
  [
    {
      start: 3250,
      duration: 1500,
      state: {
        angle: { to: 360 }
      }
    },
    {
      duration: 750,
      state: {
        angle: { to: 180 }
      }
    }
  ],
  [
    {
      start: 3250,
      duration: 1500,
      state: {
        color: { from: "__tertiary", to: "__secondary" }
      },
      easing: d3Ease.easeLinear
    },
    {
      duration: 750,
      state: {
        color: { from: "__tertiary", to: "__primary" }
      },
      easing: d3Ease.easeLinear
    }
  ]
]),
`}</code></pre>
    <p>{`This region's a bit of a doozy, so let's walk through each layer:`}</p>
    <ol>
      <li parentName="ol">{`The first layer scripts the "yo-yo" effect of the square bouncing off the bottom of the frame. It uses the `}<inlineCode parentName="li">{`loop`}</inlineCode>{` property, which we'll explain more in a minute.`}</li>
      <li parentName="ol">{`The second layer rotates the square. In the circle div, we use the angle data in a `}<a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/transform-function/rotate"
        }}>{`CSS transformation function`}</a>{`.`}</li>
      <li parentName="ol">{`The third layer changes the square's color. React Ensemble can morph (or `}<em parentName="li">{`interpolate`}</em>{`) many different types of values, including colors. For more information, see the `}<a parentName="li" {...{
          "href": "/docs/guides/easing-and-interpolating"
        }}>{`Easing and Interpolating guide`}</a>{`.`}</li>
    </ol>
    <h3 {...{
      "id": "looping-regions"
    }}>{`Looping Regions`}</h3>
    <p>{`The first region of our group has a special property, `}<inlineCode parentName="p">{`loop`}</inlineCode>{`, which we can use to loop regions within the animation.
Check out the complete reference in the `}<a parentName="p" {...{
        "href": "/docs/guides/looping"
      }}>{`Looping guide`}</a>{`.`}</p>
    <p>{`This region's `}<inlineCode parentName="p">{`loop.count = 1`}</inlineCode>{`, meaning that the region will play twice: once like normal, and then looping a single time.
Setting `}<inlineCode parentName="p">{`loop.boomerang = true`}</inlineCode>{` will make the region run backwards to reset to its starting state after every iteration.`}</p>
    <h3 {...{
      "id": "the-finished-product"
    }}>{`The Finished Product`}</h3>
    <p>{`Congratulations! You now have a complete animation that encapsulates many of the features React Ensemble has to offer.`}</p>
    <QuickStart mdxType="QuickStart" />
    <p>{`When you're ready, jump into the `}<a parentName="p" {...{
        "href": "/docs/guides"
      }}>{`Guides`}</a>{` or check out the `}<a parentName="p" {...{
        "href": "/docs/api"
      }}>{`API Reference`}</a>{`. Have a wonderful time animating!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      